const LoadingChat = () => {
return (


    <div class='flex space-x-1  items-center dark:invert'>
    <span class='sr-only'>Loading...</span>
     <div class='h-1 w-1 bg-gray-500 rounded-full animate-bounce [animation-delay:-0.3s]'></div>
   <div class='h-1 w-1 bg-gray-500 rounded-full animate-bounce [animation-delay:-0.15s]'></div>
   <div class='h-1 w-1 bg-gray-500 rounded-full animate-bounce'></div>
</div>

)

}
export default LoadingChat;
