import Cards from "./Cards";
import Filtros from "./Filtros";
import "../index.css"
import CaixaDeSugestoes from "./CaixaDeSugestoes";
import DataSinc from "./DataSinc";
import ChatWindow from "../components/ChatWindow";
import useAuth from '../hooks/useAuth';

const Home = () => {
    const { auth } = useAuth();
    const {habilitaChat} = auth;

    return (
   
        <>
            <div className="home" >
                <Filtros />
                <Cards />
                <DataSinc />
                <CaixaDeSugestoes />
               {habilitaChat &&  <ChatWindow />}
            </div>
        </>
    )
}

export default Home
