import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import { Form, Button, InputGroup, InputGroupText, Input, Alert, Spinner } from 'reactstrap'
import imgGestorLogin from '../resources/gestorLogin.png';
import { cnpjMask } from '../utils/masks';
import axios from '../api/axios';
import useAuth from '../hooks/useAuth';
import { LockClosedIcon } from '@heroicons/react/20/solid'


const LOGIN_URL = '/login';

const Login = () => {
  const { setAuth } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";
  const [cnpj, setCnpj] = useState('');
  const [usuario, setUsuario] = useState('');
  const [senha, setSenha] = useState('');
  const [lembrarLogin, setLembrarLogin] = useState(false); // Estado para lembrar do login
  const userRef = useRef(null);
  const [errMsg, setErrMsg] = useState('');
  const [logando, setLogando] = useState(false);

  useEffect(() => {
    const savedLoginData = localStorage.getItem('loginData');

    if (savedLoginData) {
      const { cnpj, usuario, senha, lembrarLogin } = JSON.parse(savedLoginData);
      setCnpj(cnpj);
      setUsuario(usuario);
      setSenha(senha);
      setLembrarLogin(lembrarLogin);
    }

    userRef.current.focus();
  }, []);

  const logar = async (e) => {
    e.preventDefault();
    setLogando(true);

    try {
      const response = await axios.post(
        LOGIN_URL,
        JSON.stringify({ cpfUsuario: usuario, idUsuario: usuario, senha, cnpjfilial: cnpj }),
        {
          headers: { 'Content-Type': 'application/json' },
        }
      );
      
      const accessToken = response?.data?.accessToken;
      const identificadorAplicativoWeb = response?.data?.identificadorAplicativoWeb;
      const filialList = response?.data?.filialList;
      const acessoFiliais = response?.data?.acessoFiliais;
      const habilitaChat = response?.data?.habilitaChat;

      setAuth({ usuario, cnpj, accessToken, identificadorAplicativoWeb, acessoFiliais, filialList, habilitaChat});

      if (lembrarLogin) {
        const loginData = JSON.stringify({ cnpj, usuario, senha, lembrarLogin });
        localStorage.setItem('loginData', loginData);
      } else {
        localStorage.removeItem('loginData');
      }

      navigate(from, { replace: true });
    } catch (err) {
      if (!err?.response) {
        setErrMsg('No Server Response');
      } else if (err.response?.status === 400) {
        setErrMsg('Favor informar um usuário');
      } else if (err.response?.status === 401) {
        setErrMsg(err.response?.data);
      } else {
        setErrMsg('Sem Acesso ao servidor.');
      }
    } finally {
      setLogando(false);
    }
  };
    return (
        <>
            {!logando && errMsg && <Alert fade color='warning' className='mt-2' >{errMsg}</Alert >}
                {logando && <div className="text-center mt-2"> <Spinner color="rgb(37 129 124)" size="sm" /> Aguarde </div>}
          


            <div className="flex min-h-full mt-12 items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="w-full max-w-md space-y-8">
          <div>
            <img
              className="mx-auto h-40 w-auto"
              src={imgGestorLogin}
              alt="ToolsGestorMob"
            />
          </div>
          <form  onSubmit={(e) => logar(e)} className= {!logando && errMsg ? "formLogin" : "mt-1 space-y-6"}  action="#" method="POST">
            <input type="hidden" name="remember" defaultValue="true" />
            <div className="-space-y-px rounded-md shadow-sm">
              <div>
                <label htmlFor="cnpj" className="sr-only">
                  CNPJ
                </label>
                <input
                  id="cnpj"
                  name="cnpj"
                  type="cnpj"
                  autoComplete="cnpj"
                  required
                  onChange={(e) => setCnpj(e.target.value)}
                  value={cnpjMask(cnpj)}
                  className="relative mb-2 block w-full appearance-none rounded-none rounded-t-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-teal-500 focus:outline-none focus:ring-teal-500 sm:text-sm"
                  placeholder="CNPJ"
                />
              </div>
              
              <div>
                <label htmlFor="username" className="sr-only">
                  Usuário
                </label>
                <input
                  id="username"
                  name="username"
                  type="text"
                  autoComplete="username" 
                  onChange={(e) => setUsuario(e.target.value)}
                  ref={userRef}
                  value={usuario}
                  required
                  className="relative mb-2 block w-full appearance-none rounded-none rounded-b-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-teal-500 focus:outline-none focus:ring-teal-500 sm:text-sm"
                  placeholder="Usuário"
                />
              </div>
              <div>
                <label htmlFor="password" className="sr-only">
                  Senha
                </label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="password"
                  onChange={(e) => setSenha(e.target.value)}
                  value={senha}
                  required
                  className="relative block w-full appearance-none rounded-none rounded-t-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-teal-500 focus:outline-none focus:ring-teal-500 sm:text-sm"
                  placeholder="Senha"
                />
              </div>
            </div>
            <div className="flex items-center justify-between">
                      <div className="flex items-start">
                          <div className="flex items-center h-5">
                            <input id="remember" aria-describedby="remember" type="checkbox"  checked={lembrarLogin} onChange={() => setLembrarLogin(!lembrarLogin)} className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 " required=""/>
                          </div>
                          <div className="ml-3 text-sm">
                            <label for="remember" className= "text-gray-500 dark:text-gray-300" >Salvar informações de login</label>
                          </div>
                      </div>
                     
                  </div>
            <div>
              <button
                type="submit"
                disabled={logando}
                style={{ backgroundColor: '#00C7D4' }}
                className="group relative flex w-full justify-center rounded-md border border-transparent py-2 px-4 text-sm font-medium text-white hover:bg-teal-600 focus:outline-none focus:ring-2 focus:ring-teal-500 focus:ring-offset-2"
              >
                <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                  <LockClosedIcon className="h-5 w-5 text-blue-50 group-hover:text-cyan-50" aria-hidden="true" />
                </span>
                Login 
              </button>
            </div>
            
          </form>
        </div>
      </div>
    
        </>

    )
}

export default Login