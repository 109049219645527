import axios from 'axios';

const customAxios = axios.create({
  baseURL: 'https://teste.toolsgestormob.com.br:8443', 
  headers: {
    'Content-Type': 'application/json',
  },
});

export default customAxios;
